@font-face {
  font-family: "gillsans_r";
  src: url("/assets/tw_fonts/GillSans/GillSans-Regular.ttf");
}
@font-face {
  font-family: "Zapfino";
  font-style: normal;
  src: url("/assets/tw_fonts/Zapfino/Zapfino.ttf");
}
@font-face {
  font-family: "Zapfino_bold";
  font-style: bold;
  src: url("/assets/tw_fonts/Zapfino/Zapfino.ttf");
}
@font-face {
  font-family: "NeutraText";
  font-style: normal;
  src: url("/assets/tw_fonts/NeutraText/NeutraText-Book.otf");
}
@font-face {
  font-family: "NeutraText_bold";
  font-style: normal;
  src: url("/assets/tw_fonts/NeutraText/NeutraText-Bold.otf");
}
@font-face {
  font-family: "Pacifico";
  font-style: normal;
  src: url("/assets/tw_fonts/Pacifico/Pacifico.ttf");
}
@font-face {
  font-family: "Pacifico_bold";
  font-style: bold;
  src: url("/assets/tw_fonts/Pacifico/Pacifico.ttf");
}
@font-face {
  font-family: "ACaslonPro";
  font-style: normal;
  src: url("/assets/tw_fonts/ACaslonPro/ACaslonPro-Regular.otf");
}
@font-face {
  font-family: "ACaslonPro_bold";
  font-style: bold;
  src: url("/assets/tw_fonts/ACaslonPro/ACaslonPro-Regular.otf");
}
@font-face {
  font-family: "OptimaLT";
  font-style: normal;
  src: url("/assets/tw_fonts/OptimaLT/Optima.TTF");
}
@font-face {
  font-family: "OptimaLT_bold";
  font-style: bold;
  src: url("/assets/tw_fonts/OptimaLT/Optima_bold.TTF");
}
@font-face {
  font-family: "American_Typewriter_Medium_BT";
  font-style: bold;
  src: url("/assets/tw_fonts/AmericanTypewriter/American_Typewriter_Medium_BT.ttf");
}
@font-face {
  font-family: "gillsans_sb";
  font-style: normal;
  src: url("/assets/tw_fonts/GillSans/GillSans-SemiBold.ttf");
}
@font-face {
  font-family: "gillsans_light";
  src: url("/assets/tw_fonts/GillSans/GillSans-Light.ttf");
}

@font-face {
  font-family: "gillsans_bold";
  font-style: bold;
  src: url("/assets/tw_fonts/GillSans/GillSans-Bold.ttf");
}

@font-face {
  font-family: "avenir_black_r";
  font-style: normal;
  src: url("/assets/tw_fonts/Avenir/Avenir-Black-03.ttf");
}

@font-face {
  font-family: "avenir_book_r";
  font-style: normal;
  src: url("/assets/tw_fonts/Avenir/Avenir-Book-01.ttf");
}
@font-face {
  font-family: "avenir_bold";
  src: url("/assets/tw_fonts/Avenir/Avenir-Heavy-05.ttf");
}
@font-face {
  font-family: "avenir_light";
  font-style: normal;
  src: url("/assets/tw_fonts/Avenir/Avenir-Light-07.ttf");
}
@font-face {
  font-family: "avenir_sb";
  font-style: normal;
  src: url("/assets/tw_fonts/Avenir/Avenir-Medium-09.ttf");
}
@font-face {
  font-family: "avenir_roman";
  font-style: normal;
  src: url("/assets/tw_fonts/Avenir/Avenir-Roman-12.ttf");
}
@font-face {
  font-family: "Helvetica";
  font-style: normal;
  src: url("/assets/tw_fonts/Helvetica/Helvetica.ttf");
}
@font-face {
  font-family: "LucidaGrande";
  font-style: normal;
  src: url("/assets/tw_fonts/LucidaGrande/LucidaGrande.ttf");
}
@font-face {
  font-family: "millerdisplay_light";
  src: url("/assets/tw_fonts/MillerDisplay/millerdisplay_light.ttf");
}
@font-face {
  font-family: "Miller_DisplayRoman";
  src: url("/assets/tw_fonts/MillerDisplay/Miller-DisplayRomanSC.otf");
}
@font-face {
  font-family: "TrajanPro3";
  src: url("/assets/tw_fonts/TrajanPro3/TrajanPro3-Regular.otf");
}
@font-face {
  font-family: "Oswald_Regular";
  src: url("/assets/tw_fonts/Oswald/Oswald-Regular.ttf");
}
@font-face {
  font-family: "Oswald_Light";
  src: url("/assets/tw_fonts/Oswald/Oswald-ExtraLight.ttf");
}
@font-face {
  font-family: "ProximaNova_Regular";
  src: url("/assets/tw_fonts/Proxima/ProximaNova-Regular.otf");
}
@font-face {
  font-family: "ProximaNova_Thin";
  src: url("/assets/tw_fonts/Proxima/Proxima Nova Alt Thin.otf");
}

body {
  margin: 0;
  font-family: "gillsans_r", "gillsans_sb", "gillsans_light", "gillsans_bold",
    "avenir_black_r", "avenir_book_r", "avenir_bold", "avenir_light",
    "avenir_sb", "avenir_roman", '"Helvetica Neue"', "Zapfino_bold", "Zapfino",
    "Pacifico", "Pacifico_bold", "ACaslonPro", "ACaslonPro_bold", "OptimaLT",
    "OptimaLT_bold", "NeutraText", "NeutraText_bold", "Helvetica",
    "LucidaGrande", "American_Typewriter_Medium_BT", "Miller_DisplayRoman",
    "millerdisplay_light", "TrajanPro3", "Oswald_Regular", "ProximaNova_Regular",
    "Oswald_Light", "ProximaNova_Thin", sans-serif;
}

html {
  font-size: 100%; /* 100% of 16px = 16px */
}

p {
  margin: 0;
}

.ql-snow .ql-editor pre.ql-syntax {
  background-color: transparent !important;
  color: #000 !important;
  overflow: visible;
  font-size: 14px;
}

.ql-snow a {
  color: #410166 !important;
}

a {
  color: #410166 !important;
}
.ql-editor {
  font-family: "NeutraText", sans-serif;
  font-size: 16px;
}
