.parent-crop-box {
  border: solid 1px #5e94e0 !important;
}
.parent-crop-box > p {
  position: absolute;
  background-color: white;
  font-family: "gillsans_r", sans-serif;
  font-size: 14px;
  color: blue;
  padding: 2px;
  left: 1px;
  top: 1px;
}

.child-crop-box {
  border: solid 2px #dede0f;
  position: absolute;
  top: 24%;
  height: 50%;
  width: 99%;
}

.child-crop-box > p {
  position: absolute;
  background-color: white;
  font-family: "gillsans_r", sans-serif;
  font-size: 14px;
  color: #dede0f;
  padding: 2px;
  left: 1px;
  top: 1px;
}

.square-crop-box {
  border-radius: 0 !important;
  border: solid 1px #5e94e0 !important;
  box-shadow: none !important;
}

.circle-crop-box {
  border-color: #5e94e0 !important;
}
